import React from 'react';
import {WindowAlert, WindowExplorer} from "packard-belle";
import Window from "./Window";

const noop = () => {};
let width = (window.innerWidth < 1200) ? window.innerWidth - 20 : 1150;
let height = (window.innerHeight < 900) ? window.innerHeight - 20 : 850;

export function App() {
    return (
        <Window
            initialWidth={width}
            initialHeight={height}
            initialX={(window.innerWidth < 1200) ? 0 : (window.innerWidth / 2) - 575}
            initialY={(window.innerHeight < 900) ? 0 : (window.innerHeight / 2) - 425}
            width={ width }
            height={ height }
            Component={WindowExplorer}
            menuOptions={[
                {
                    title: "Contact",
                    options: [
                        [{ title: "e-mail us", onClick: () => { parent.location='mailto:daan0308@gmail.com' } }],
                        { title: "give us a call at: 06-42 97 87 26" },
                        { title: "see us on youtube", onClick: () => { parent.location="https://www.youtube.com/channel/UCH86Tbcux_w1GoOjZcCqv5w"} }
                    ]
                }
            ]}
            title="https://www.J4BBSite.nl // More coming soon"
            onClose={noop}
            onRestore={noop}
            footer={[<div>Test</div>, <div>Oh</div>]}
            resizable={true}
        >
            <div className={"bg-gray-100 relative h-full"}>
                <iframe className={"absolute top-0 left-0 h-full w-full focus:border-none"}
                        src="https://www.youtube-nocookie.com/embed/wSnW9kKneFo?modestbranding=1&autohide=1&showinfo=0&controls=0&autoplay=1"
                        title="YouTube video player" frameBorder="0"
                        allow="autoplay; encrypted-media;"
                        scrolling={"yes"}
                />
            </div>


        </Window>
    );
}